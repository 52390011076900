exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-us-about-us-tsx": () => import("./../../../src/templates/AboutUs/AboutUs.tsx" /* webpackChunkName: "component---src-templates-about-us-about-us-tsx" */),
  "component---src-templates-about-us-subpages-contact-us-contact-us-tsx": () => import("./../../../src/templates/AboutUs/subpages/ContactUs/ContactUs.tsx" /* webpackChunkName: "component---src-templates-about-us-subpages-contact-us-contact-us-tsx" */),
  "component---src-templates-about-us-subpages-management-management-tsx": () => import("./../../../src/templates/AboutUs/subpages/Management/Management.tsx" /* webpackChunkName: "component---src-templates-about-us-subpages-management-management-tsx" */),
  "component---src-templates-about-us-subpages-team-team-tsx": () => import("./../../../src/templates/AboutUs/subpages/Team/Team.tsx" /* webpackChunkName: "component---src-templates-about-us-subpages-team-team-tsx" */),
  "component---src-templates-connect-with-us-connect-with-us-tsx": () => import("./../../../src/templates/ConnectWithUs/ConnectWithUs.tsx" /* webpackChunkName: "component---src-templates-connect-with-us-connect-with-us-tsx" */),
  "component---src-templates-events-events-tsx": () => import("./../../../src/templates/Events/Events.tsx" /* webpackChunkName: "component---src-templates-events-events-tsx" */),
  "component---src-templates-events-subpages-event-event-tsx": () => import("./../../../src/templates/Events/subpages/Event/Event.tsx" /* webpackChunkName: "component---src-templates-events-subpages-event-event-tsx" */),
  "component---src-templates-main-main-tsx": () => import("./../../../src/templates/Main/Main.tsx" /* webpackChunkName: "component---src-templates-main-main-tsx" */),
  "component---src-templates-news-details-news-details-tsx": () => import("./../../../src/templates/NewsDetails/NewsDetails.tsx" /* webpackChunkName: "component---src-templates-news-details-news-details-tsx" */),
  "component---src-templates-news-main-news-main-tsx": () => import("./../../../src/templates/NewsMain/NewsMain.tsx" /* webpackChunkName: "component---src-templates-news-main-news-main-tsx" */),
  "component---src-templates-privacy-policy-privacy-policy-tsx": () => import("./../../../src/templates/PrivacyPolicy/PrivacyPolicy.tsx" /* webpackChunkName: "component---src-templates-privacy-policy-privacy-policy-tsx" */),
  "component---src-templates-simple-page-simple-page-tsx": () => import("./../../../src/templates/SimplePage/SimplePage.tsx" /* webpackChunkName: "component---src-templates-simple-page-simple-page-tsx" */),
  "component---src-templates-technologies-subpages-industries-industries-tsx": () => import("./../../../src/templates/Technologies/subpages/Industries/Industries.tsx" /* webpackChunkName: "component---src-templates-technologies-subpages-industries-industries-tsx" */),
  "component---src-templates-technologies-technologies-tsx": () => import("./../../../src/templates/Technologies/Technologies.tsx" /* webpackChunkName: "component---src-templates-technologies-technologies-tsx" */),
  "component---src-templates-vacancies-vacancies-tsx": () => import("./../../../src/templates/Vacancies/Vacancies.tsx" /* webpackChunkName: "component---src-templates-vacancies-vacancies-tsx" */)
}

